import { useState, useEffect } from "react";
import shuffledGameImages from "../Components/GameImages";

const Tile = ({flippedCount, setFlippedCount}) => {
    const [selectedTiles, setSelectedTiles] = useState([]);
    const [matchedTiles, setMatchedTiles] = useState([]);

    useEffect(() => {
        const lastGameTime = localStorage.getItem('lastGameTime');
        const minTimeBetweenGamesWin = 48 * 60 * 60 * 1000;
        const minTimeBetweenGamesLose = 24 * 60 * 60 * 1000;
        const loseState = localStorage.getItem('lose');
        const winState = localStorage.getItem('win');

        if (loseState === 'true') {
            if (lastGameTime && new Date().getTime() - parseInt(lastGameTime) < minTimeBetweenGamesLose) {
                // setIsClickable(false);
            }
        }

        if (winState === 'true') {
            if (lastGameTime && new Date().getTime() - parseInt(lastGameTime) < minTimeBetweenGamesWin) {
                // setIsClickable(false);
            }
        }
    }, []);

    const handleClick = (card) => {
        if (selectedTiles.length < 2 && !selectedTiles.includes(card)) {
            setSelectedTiles(prevSelectedTiles => [...prevSelectedTiles, card]);
        }
    }

    useEffect(() => {
        if (selectedTiles.length === 2) {
            setTimeout(() => {
                checkMatch();
                setSelectedTiles([]);
            }, 500);
        }
    }, [selectedTiles]);

    const checkMatch = () => {
        if (selectedTiles[0].match === selectedTiles[1].match) {
            console.log("match")
            setMatchedTiles(prevMatchedTiles => [...prevMatchedTiles, ...selectedTiles]);
            setFlippedCount(flippedCount + 1);
        }
    }

    return (
        <>
            {shuffledGameImages.map((card) => (
                <div 
                    key={card.id} 
                    className="tile"
                    onClick={() => handleClick(card)}
                >
                    <img 
                        id={card.id} 
                        src={selectedTiles.includes(card) || matchedTiles.includes(card) ? card.cardBack : card.cardFront} 
                        alt={`Tile ${card.id}`} 
                    />
                </div>
            ))}
        </>
    );
};

export default Tile;